.popup {
    position: absolute;
    top: calc(20vh);
    left: calc(50vw - 360px);
    padding: 20px;
    background: white;
    text-align: center;
    width: 720px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 25px;
    box-shadow: 0px 0px 35px #C8C8C8;
    z-index: 1000;
}

@media screen and (max-width: 850px) {
    .popup {
        left: calc(10vw);
        width: 80vw;
        height: 380px;
    }

    .popup h1 {
        font-size: 28px;
    }

    .popup h3 {
        font-size: 18px;
    }
}