.accueil {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px 0 120px 0;
}

.accueil h1 {
    font-weight: 600;
}

.accueil .button_container {
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.accueil button {
    width: 237px;
    height: 50px;
    display: block;
    border-radius: 25px;
    background-color: var(--color-primaire);
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    line-height: 50px;
    border: none;
}

.accueil button a {
    color: #000;
    text-decoration: none;
}

.accueil button:hover {
    background-color: var(--color-foncee);
}

.accueil a:hover path {
    fill: var(--color-foncee);
}

.accueil img.logo {
    width: 450px;
    height: 450px;
}

.accueil .confidential_link {
    z-index: 100;
    color: var(--color-foncee);
    position: absolute;
    bottom: 66px;
}

.accueil .confidential_link:hover {
    color: #000;
}

@media screen and (max-width: 600px) {
    .accueil img.logo {
        width: 150px;
        height: 150px;
    }

    .accueil .button_container {
        width: 80%;
    }
}

@media screen and (min-width: 600px) and (max-height: 850px) {
    .accueil img.logo {
        width: 250px;
        height: 250px;
    }
}