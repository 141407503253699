.confidentialite {
  line-height: 1.6;
  padding: 0;
  color: #000000;
  width: 100%;
  height: 100%;
  padding: 50px;
  overflow: scroll;
  z-index: 999;
}

.confidentialite h1 {
  color: #fe7276;
  text-align: center;
}

.confidentialite h2 {
  color: #009a73;
  padding-left: 25px;
}

.confidentialite ul {
  list-style-position: inside;
}

.confidentialite li {
  margin-bottom: 10px;
  text-justify: auto;
}

.confidentialite strong {
  color: #000;
}

.confidentialite p,
.confidentialite ul {
  margin-bottom: 20px;
  text-justify: auto;
}
