.check, .no_check {
    width: 22px;
    height: 22px;
    stroke-width: 2.8px;
    margin: 0 8px;
}

.check{
    stroke: var(--color-primaire)
}

.no_check{
    stroke: var(--color-secondaire)
}