.slider {
  width: 100%;
  height: 100%;
}

.slider .container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider .prev {
  position: absolute;
  left: 10px;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider .next {
  position: absolute;
  right: 10px;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
