.top {
    position: absolute;
    top: 0;
    left: 0;
    height: 20vh;
    z-index: 5;
}

.bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 20vh;
    z-index: 5;
}

.closed {
    position: absolute;
    width: 45px;
    height: 45px;
    top: 10px;
    right: 10px;
    display: flex;
    border-radius: 25px;
    background-color: var(--color-primaire);
    text-align: center;
    cursor: pointer;
    line-height: 50px;
    border: none;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) and (max-height: 850px) {
    .closed {
        width: 30px;
        height: 30px;
    }
    .closed svg {
        width: 12px;
        height: 12px;
    }
}