.prix {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 50px;
    align-items: center;
    flex-direction: column;
}

.prix .titre_prix {
    text-align: center;
}

.prix .card_container {
    display: flex;
    width: 70%;
    height: 80%;
    justify-content: space-between;
    align-items: center;
}

.prix .card {
    width: 30%;
    height: 100%;
    padding: 20px;
    position: relative;
    border-radius: 25px;
    box-shadow: 0px 0px 35px #c8c8c8;
}

.prix .card:hover {
    box-shadow: 0px 0px 45px #aaaaaa;
}

.prix .card h1 {
    color: var(--color-foncee);
    font-weight: bolder;
    font-size: 38px;
    text-align: center;
    margin-bottom: 25px;
}

.prix .card p {
    margin-bottom: 25px;
    text-align: justify;
}

.prix .card h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
}

.prix .card h2 strong {
    font-weight: 900;
    font-size: 50px;
    color: var(--color-primaire);
}

.prix .button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prix button {
    width: 237px;
    height: 50px;
    display: block;
    border-radius: 25px;
    background-color: var(--color-primaire);
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    line-height: 50px;
    border: none;
}

.prix hr {
    margin: 15px 0;
}

.prix button:hover {
    background-color: var(--color-foncee);
}

.prix .choix {
    border: solid 2px var(--color-foncee);
}

.prix .card ul {
    list-style: none;
    padding: 0 18px;
}

.prix .card li {
    font-size: 20px;
    margin: 8px 0;
    line-height: 1.2;
}

.prix .choix_container {
    background-color: var(--color-foncee);
    color: white;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    left: 0;
    border-radius: 0 0 22px 22px;
    text-align: center;
    align-content: center;
    font-size: x-large;
    font-weight: 700;
}

@media screen and ((max-width: 1620px) or (max-height: 900px)) {
    .prix .card_container {
        width: 90%;
        gap: 25px;
        padding-top: 35px;
        padding-bottom: 35px;
    }
    .prix .card {
        padding: 12px;
    }
    .prix .card p {
        font-size: 12px;
        margin-bottom: 12px;
    }
    .prix .card h1 {
        font-size: 21px;
        margin-bottom: 12px;
    }
    .prix .card h2 {
        font-size: 14px;
        margin-bottom: 12px;
    }
    .prix .card h2 strong {
        font-size: 25px;
    }
    .prix button {
        font-size: 13px;
        height: 35px;
        line-height: 25px;
    }
    .prix .card ul {
        font-size: 12px;
        padding: 0 5px;
    }
    .prix .card .check {
        margin: 0 4px;
    }
    .prix .card li {
        font-size: 12px;
    }
    .prix .choix_container {
        font-size: 15px;
        height: 25px;
    }
}

@media screen and ((max-width: 1000px) or (max-height: 625px)) {
    .prix .card_container {
        flex-direction: column;
        overflow: scroll;
    }
    .prix .card {
        width: 80%;
    }
    .prix {
        padding: 50px 50px 0 50px;
    }
}