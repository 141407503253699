:root {
  font-family: "Inter", sans-serif;
  --color-primaire: #27d4a9;
  --color-foncee: #009a73;
  --color-noir: #000;
  --color-secondaire: #FE7276;
}

.root {
  width: 100vw;
  height: 100vh;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  width: 100vw;
  height: 100vh;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}